import { DocumentConst } from "constants/document/DocumentConst";
import { DocumentHeaderEntity, EMPTY_DOCUMENT_HEADER_ENTITY } from "domain/entity/Document/DocumentHeaderEntity";
import { DocumentEnquiryRepository } from "domain/repository/Document/DocumentEnquiryRepo";
import _ from "lodash";
import { DocumentEnquiryDetailModel } from "presentation/model/DocumentEnquiry/DocumentEnquiryDetailModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface DocumentEnquiryDetailVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<DocumentEnquiryDetailModel>> | ((value: SetStateAction<DocumentEnquiryDetailModel>) => void),
    ]    
    docEnquiryRepo: DocumentEnquiryRepository,
}

export const DocumentEnquiryDetailVM = ({ dispatch, docEnquiryRepo }: DocumentEnquiryDetailVMProps) => {
    const [docEnqDtlDispatch] = dispatch;

    const onPageInit = (currentDocHdr: DocumentHeaderEntity | null) => {
        let docHdr: DocumentHeaderEntity ;
        let publishAtWebsiteInd;
        let issueByEdiInd;
        const docIssue = currentDocHdr?.docIssue;

        if (docIssue?.issueType) {  
            publishAtWebsiteInd = docIssue.issueType.split(';').includes(DocumentConst.issueType.ISSUE_TYPE_E_INVOICE) ? 'Y' : 'N';  
            issueByEdiInd = docIssue.issueType.split(';').includes(DocumentConst.issueType.ISSUE_TYPE_EDI) ? 'Y' : 'N';  
        }

        docHdr = {
            ...currentDocHdr,
            publishAtWebsiteInd: currentDocHdr?.publishAtWebsiteInd??publishAtWebsiteInd,
            issueByEdiInd: currentDocHdr?.issueByEdiInd??issueByEdiInd,
        }

        docEnqDtlDispatch(prevState => {
            return {
                ...prevState,
                docHdr: {
                    ...docHdr,
                },
                detailState:{
                    ...prevState.detailState,
                    isEditable:  !!(docHdr?.id),
                    isRead: docHdr?.id !== null,
                }
            }
        })
    }

    const onShowLoading = () => {
        docEnqDtlDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        docEnqDtlDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const onCloseScreen = () => {

    }

    const onCancel = () => {
        docEnqDtlDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    editingHeader: {
                        ...EMPTY_DOCUMENT_HEADER_ENTITY
                    },
                    isRead: true,
                    isEditable: true,
                }
            }
        })
    }

    const onEdit = (docHdr: DocumentHeaderEntity) => {
        docEnqDtlDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    isRead: false,
                    isEditable: true, 
                    editingHeader: {
                        ...docHdr,
                    },
                }
            }
        })
    }

    const onSaveClicked = () => {

        docEnqDtlDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    isSaveClicked: true,
                    isEditable: true,
                    editingHeader: EMPTY_DOCUMENT_HEADER_ENTITY,
                }
            }
        })
    }

    const onSave = async (docHdr: DocumentHeaderEntity) => {
        const savedHdr = await docEnquiryRepo.saveDocHdr(docHdr);
        docEnqDtlDispatch(prevState => {
            return {
                ...prevState,
                docHdr: savedHdr,
                detailState:{
                    ...prevState.detailState,
                    isRead: true,
                }
            }          
        })

        return savedHdr;
    }

    const onSearch = async (docHdrId: number) =>{
        
        await docEnquiryRepo.getDocDtlItemByHdrId(docHdrId).then((data) => {
            return docEnqDtlDispatch(prevState => {
                return {
                        ...prevState,
                        docDtlItems: data,
                    }          
                })
        })
    }

    const onSearchDocHdrById = async(hdrId:number) => {
       const docHdrs = await docEnquiryRepo.searchDocument({
            previewRefNo: hdrId,
        })
        let rtnHdr = null;
        if(!_.isEmpty(docHdrs)){
            rtnHdr = docHdrs[0];
        }
        return rtnHdr;
    }

    const onHeaderFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
      if (_.isArray(val)) {
        val = _.uniq(val?.map((item: any) => item.value || item.key));
      }     

      if(fieldKey !== 'yourRef' && fieldKey !== 'ourRef'){
        val = val.toUpperCase().replace(/\s+/g, '');
      }

      return docEnqDtlDispatch(prevState => {
        return {
            ...prevState,
            detailState: {
                    ...prevState.detailState,
                    editingHeader: {
                        ...prevState.detailState.editingHeader,
                        [fieldKey]: val,
                    }
                },
            }          
        })
    }
    const onRadioChange = (e: any, fieldName: string) => {
        if(e.checked){
            return docEnqDtlDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                            ...prevState.detailState,
                            editingHeader: {
                                ...prevState.detailState.editingHeader,
                                [fieldName]: e.key,
                            }
                        },
                    }          
                })
        }
    };


    return {
        onPageInit,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onCloseScreen: onCloseScreen,
        onCancel: onCancel,
        onEdit: onEdit,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onSearch,
        onSearchDocHdrById: onSearchDocHdrById,
        onHeaderFieldChange: onHeaderFieldChange,
        onRadioChange: onRadioChange,
    }
}