import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DocumentEnquiryDetailProvider } from "presentation/store/DocumentEnquiry/DocumentEnquiryDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import DocumentEnquiryDetailMaintenance from "presentation/view/section/DocumentEnquiry/Detail/DocumentEnquiryDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const DocumentEnquiryDetailContainer = () => <ANAInfoWrapper permission={Permission.DOCUMENT_ENQUIRY_DETAIL}>
    <DocumentEnquiryDetailProvider>
        <GridStyles/>
        <DocumentEnquiryDetailMaintenance/>
    </DocumentEnquiryDetailProvider>
</ANAInfoWrapper>;