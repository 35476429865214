import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { DocumentDetailItemEntity } from "domain/entity/Document/DocumentDetailItemEntity";
import { DocumentHeaderEntity } from "domain/entity/Document/DocumentHeaderEntity";
import { DocumentPrintRequestEntity } from "domain/entity/DocumentEnquiry/DocumentPrintRequestEntity";
import { DocumentEnquiryCriteria } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import documentAxiosInstance from "../axios/documentAxiosInstance";
import { DocumentEnquiryRepository } from "./DocumentEnquiryRepo";
export const DocumentEnquiryRepoImpl = (): DocumentEnquiryRepository => {
    const url = '/v1/documentEnquiry';

    /**
     * Search the document invoice data by criteria
     * 
     * @param criteria 
     * @returns 
     */
    const searchDocument = async(criteria: DocumentEnquiryCriteria) : Promise<DocumentHeaderEntity[]> => {
        const result = axiosPostData(documentAxiosInstance, `${url}`, criteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
        return result;
    }

    /**
     * Search the document detail by document header
     * @param docHdrId 
     * @returns 
     */
    const getDocDtlItemByHdrId = async(docHdrId: number): Promise<DocumentDetailItemEntity[]> => {
        return axiosGetData(documentAxiosInstance, `${url}/detail/${docHdrId}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    } 

    /**
     * Update the document header info.
     * 
     * @param docHdr 
     * @returns 
     */
    const saveDocHdr = async(docHdr: DocumentHeaderEntity) : Promise<DocumentHeaderEntity> => {
        const result = axiosPostData(documentAxiosInstance, `${url}/update`, docHdr).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
        return result;
    }

    /**
     * Reprint the document invoice(s) and supporting document invoice(s)
     * 
     * @param docPrintReq 
     * @returns 
     */
    const reprintDoc = async(docPrintReq: DocumentPrintRequestEntity): Promise<ResponseEntity> => {
        return await axiosPostData(documentAxiosInstance, `${url}/reprint`, docPrintReq)
    }

    return {
        searchDocument: searchDocument,
        getDocDtlItemByHdrId: getDocDtlItemByHdrId,
        saveDocHdr: saveDocHdr,
        reprintDoc: reprintDoc,
    }
}