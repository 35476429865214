import { DocumentIssuanceEntity } from "./DocumentIssuanceEntity"

export interface DocumentHeaderEntity{
    
    id?: number|null,
    
    docNo?: string|null,
    
    docType?: string|null,
    
    docDate?: Date|null,
    
    firstPrintDate?: Date|null,    
    
    billToCompany?: string|null,
    
    customerCode?: string|null,
    
    consortiumCode?: string|null,
    
    vesselCode?: string|null,
    
    voyageCode?: string|null,
    
    vesselName?: string|null,

    etd?: Date|null,
    
    etb?: Date|null,
    
    eta?: Date|null,
    
    loa?: number|null,
    
    soa?: string|null,
    
    serviceCode?: string|null,
    
    operatingTml?: string|null,
    
    opsDate?: Date|null,
    
    postingDate?: Date|null,
    
    paymentDueDate?: Date|null,
    
    arPaymentDueDate?: Date|null,

    poNo?: string|null,
    
    ourRef?: string|null,
    
    yourRef?: string|null,

    slVesselCode?: string|null,
    
    slIbVoyageCode?: string|null,
    
    slObVoyageCode?: string|null,
    
    hdrDesc1?: string|null,
    
    hdrDesc2?: string|null,
    
    interestRate?: number|null,
    
    totalAmount?: number|null,
    
    convertedTotalAmount?: number|null,

    exchangeRate?: number|null,
    
    exchangeRateRefDate?: Date|null,
    
    currencyCode?: string|null,
    
    bankNo?: string|null,
    
    localBankNo?: string|null,
    
    logoCompany?: string|null,
    
    berthingDatetimeFrom?: Date|null,
    
    berthingDatetimeTo?: Date|null,
    
    docMergeCriteriaName?: string|null,
    
    vslStartOperationDatetime?: Date|null,
    
    vslEndOperationDatetime?: Date|null,   
    
    docMergeType?: string|null,
    
    paymentTerm?: number|null,
    
    paymentUom?: string|null,
    
    adjType?: string|null,
    
    glDate?: Date|null,
    
    hdrState?: string|null,
    
    docDateForFinalize?: string|null,
    
    draftDocNo?: string|null,
    
    billingCycle?: string|null,
    
    billingCycleCode?: string|null,
    
    opsDateFrom?: Date|null,
    
    opsDateTo?: Date|null,
    
    finalizedBy?: string|null,
    
    vatPercentage?: number|null,
    
    handlingTerminal?: string|null,
    
    genByRecal?: 'Y'|'N',
    
    approvedBy?: string|null,
    
    approvedDatetime?: Date|null,
    
    approvalStatus?: string|null,
    
    moveBy?: string|null,
    
    docDateAdjFirstPrintFrom?: Date|null,
    
    docDateAdjFirstPrintTo?: Date|null,
    
    docDateAdjOpsDateFrom?: Date|null,
    
    docDateAdjOpsDateTo?: Date|null,   
    
    glCutoffDateFrom?: Date|null,
    
    glCutoffDateTo?: Date|null,        
    
    isValid?: boolean,
    
    originalDocNo?: string|null,

    country?: string|null,
    
    dateTimeForFinalize?: Date|null,
        
    creditChargeHdrReferenceId?: number|null,
    
    chargeType?: string|null,
    
    docIssueId?: number|null,
    
    docIssue?: DocumentIssuanceEntity | null,
    
    berthingPeriod?: number|null,
    
    berthingPeriodUom?: string|null,
    
    manualAssignPaymentDueDate?: string|null,
    
    estExchangeRate?: number|null,
    
    estExchangeRateRefDate?: Date|null,
    
    estConvertedTotalAmt?: number|null,
    
    errorMsg?: string|null,
    
    vslScale?: string|null,
    
    resendCount?: number|null,
    
    halfHour ?: number|null,
    
    halfMin?: number|null,
    
    docNoPrefix?: string|null,
    
    isDocNoBackDateFlag?: string|null,
    
    region?: string|null,
    
    convertedCurrencyCode?: string|null,
    
    localCurrencyCode?: string|null,
    
    localExchangeRate?: string|null,
    
    localTotalAmt?: string|null,
    
    docSignature?: string|null,
    
    agentNo?: string|null,
    
    agentName?: string|null,
    
    companyName?: string|null,
    
    customerCurrencyCode?: string|null,
    
    customerType?: string|null,
    
    publishAtWebsiteInd?: string|null,
    
    issueByEdiInd?: string|null,
    
    orderNo?: string|null,
    
    billingType?: string|null,
    
    recalByBillToCompany?: string|null,
    
    discountWithTaxInd?: string|null,
    
    firstPrint?: boolean,
    
    genEdiCount?: number|null,
    
    genEinvoiceCount?: number|null,


    [key: string]: string[] | string | boolean | number | Date | Object | null | undefined
}

export const EMPTY_DOCUMENT_HEADER_ENTITY : DocumentHeaderEntity = {
  
}