export interface EnabledDocumentEnquiryCriteria {
    all: boolean,
    voyage: boolean,
    charge:boolean,
    container: boolean,
    document: boolean,

    // Voyage
    coVslVoy: boolean,
    etd: boolean,

    // Charge
    opsDate: boolean,
    billToCompList: boolean,
    chgTypeList: boolean,
    billingTypeList: boolean,
    billingCycleList: boolean,
    billingCycleCode: boolean,

    //Container
    handlingTmlList: boolean,

    // Document
    invDate: boolean,
    invNo: boolean,
    origDocNo: boolean,
    docTypeList: boolean,
    stateList: boolean,
    publicAtWebSiteList: boolean,
    genDate: boolean,
    previewRefNo: boolean,
    issueByEdiList: boolean,
    generatedBy: boolean,
    issuedBy: boolean,
    approvalStatusList: boolean,
    approvalDatetime: boolean,

    customerCodeList: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_DOCUMENT_ENQUIRY_CRITERIA: EnabledDocumentEnquiryCriteria = {
    all: false,
    voyage: true,
    charge: true,
    container: true,
    document: true,
    coVslVoy: true,
    opsDate: true,
    invDate: true,
    etd: true,
    billToCompList: true,
    chgTypeList: false,
    billingTypeList: false,
    billingCycleList: false,
    billingCycleCode: false,
    handlingTmlList: false,
    invNo: true,
    origDocNo: true,
    docTypeList: true,
    stateList: true,
    publicAtWebSiteList: false,
    genDate: true,
    previewRefNo: true,
    issueByEdiList: false,
    generatedBy: true,
    issuedBy: true,
    approvalStatusList: true,
    approvalDatetime: false,
    customerCodeList: true,
}