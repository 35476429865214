export interface DocumentEnquiryCriteria {    
    co?: string | null,
    vsl?: string | null,
    voy?: string | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null,
    invDateFrom?: Date | null,
    invDateTo?: Date | null,
    etdDateFrom?: Date | null,
    etdDateTo?: Date | null,
    billToCompList?: string[] | null,
    chgTypeList?: string[] | null,
    stateList?: string[] | null,
    invNo?: string | null,
    handlingTmlList?: string[] | null,
    previewRefNo?: number | null,
    billingTypeList?: string[] | null,
    billingCycleCode?: string | null,
    billingCycleList?: string[] | null,

    oriDocNo?: string|null,
    docTypeList?: string[]|null,
    publicAtWebSiteList?: string[]|null,
    genDateFrom?: Date|null,
    genDateTo?: Date|null,
    issueByEdiList?: string[]|null,
    generatedBy?: string|null,
    issuedBy?: string|null,
    approvalStatusList?: string[]|null,
    approvalDatetimeFrom?: Date|null,
    approvalDatetimeTo?: Date|null,
    customerCodeList?: string[]|null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_DOCUMENT_ENQUIRY_CRITERIA : DocumentEnquiryCriteria = {
    co: "",
    vsl: "",
    voy: "",
    opsDateFrom: null,
    opsDateTo: null,
    invDateFrom: null,
    invDateTo: null,
    etdDateFrom: null,
    etdDateTo: null,
    billToCompList: [],
    chgTypeList: [],
    stateList: [],
    invNo: "",
    handlingTmlList: [],
    previewRefNo: null,
    billingTypeList: [],
    billingCycleCode: "",
    billingCycleList: [],
    genDateFrom: null,
    genDateTo: null,
    oriDocNo: null,
    docTypeList: [],
    generatedBy: null,
    issuedBy: null,
    publicAtWebSiteList: [],
    issueByEdiList: [],
    approvalDatetimeFrom: null,
    approvalDatetimeTo: null,
    approvalStatusList: [],
    customerCodeList:[]
}