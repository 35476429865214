export const DocumentEnquiryConstant = {
    Header: {
        CONTAINER: 'CONTAINER',
        VOYAGE: 'VOYAGE',
        CHARGE: 'CHARGE',
        DOCUMENT: 'DOCUMENT',
        STATE: 'State',
        BILLING_TYPE: 'Billing Type',
        CNTR_NO: 'Container No.',
        SL_VESSEL_CODE: 'S/L Vessel Code',
        SL_IB_VOYAGE_CODE: 'S/L IB Voyage Code',
        SL_OB_VOYAGE_CODE: 'S/L OB Voyage Code',
        PREVIEW_REF_NO: 'Preview Ref. No',
        OPS_DATE: 'Ops Date',
        OPS_DATE_RANGE: 'Ops Date Range',
        CHARGE_TYPE: 'Charge Type',
        INV_CURR: 'Invoice Currency',
        TTL_AMT_IN_CURR: 'Total Amount in Invoice Currency',
        LOCAL_CURR: 'Local Currency',
        TTL_AMT_IN_LOCAL_CURR: 'Total Amount in Local Currency',
        DOC_STATE: 'Doc. State',
        BILL_TO_COMPANY: 'Bill-To Company',
        CONSORTIUM_CODE: 'Consortium Code',
        VESSEL_CODE: 'Vessel Code',
        VOYAGE_CODE: 'Voyage Code',
        ETD: 'Dep Date/Time',
        ETD_RANGE: 'ETD Range',
        HANDLING_TML: 'Handling Tml.',
        VSL_NAME: 'Vessel Name',
        CURRENCY: 'Contract Currency',
        TTL_AMT_IN_CONT_CURR: 'Total Amount in Contract Currency',
        BILL_ADDR_1: 'Billing Address 1',
        BILL_ADDR_2: 'Billing Address 2',
        BILL_ADDR_3: 'Billing Address 3',
        BILL_2ND_ADDR_1: 'Billing 2nd Address 1',
        BILL_2ND_ADDR_2: 'Billing 2nd Address 2',
        BILL_2ND_ADDR_3: 'Billing 2nd Address 3',
        PONO: 'P.O. No.',
        YOUR_REF: 'Your Ref.',
        OUR_REF: 'Our Ref.',
        INVOICE_NO: 'Doc. No.',
        INVOICE_DATE: 'Doc. Date',
        INVOICE_DATE_RANGE: 'Doc. Date Range',
        CREDIT_NOTE_NO: 'Credit Note No.',
        CREDIT_NOTE_DATE: 'Credit Note Date',
        DRAFT_INVOICE_NO: 'Draft Doc. No.',
        DRAFT_CREDITE_NOTE_NO: 'Draft Credit Note No.',
        BILLING_CYCLE_CODE: 'Billing Cycle Code',
        OPS_DATE_TIME: 'Ops Date Time',
        CO_VSL_VOY: 'Co/Vsl/Voy',
        INV_CRE_NOTE_NO: 'Doc. No.',
        VSL: 'Vsl',
        VOY: 'Voy',
        BILL_CYCLE: 'Billing Cycle',
        ORI_DOC_NO: 'Ori Doc. No',
        DOC_TYPE: 'Doc. Type',
        GEN_DATE: 'Generation Date',
        GEN_DATE_RANGE: 'Gen. Date Range',
        GEN_BY: 'Generated By',
        ISSUED_BY: 'Issued By',
        EXP_TYPE: 'Export Type',
        PUB_WEB_SITE: 'Publish at Website',
        APPR_BY: 'Approved By',
        APPR_STATUS: 'Approval Status',
        APPR_DATE: 'Approved Date/Time',
        APPR_DATE_RANGE: 'Approved Date Range',
        CUST_CODE: 'Customer Code',
        PRINT_DATE: 'Print Date'
      },

    Detail: {
        DTL_NO: 'Detail No',
        TAR_TYPE: 'Tar Type',
        TAR_CODE: 'Tar Code',
        TAR_DESC: 'Tar Desc.',
        CHG_TYPE: 'Charge Type',
        RATE_INV_CURR: 'Rate (Invoice Cur.)',
        RATE_CONT_CURR: 'Rate (Contract Cur.)',
        RATE_LOC_CURR: 'Rate (Local Cur.)',
        AMT_INV_CURR: 'Amount (Invoice Cur.)',
        AMT_CONT_CURR: 'Amount (Contract Cur.)',
        AMT_LOC_CURR: 'Amount (Local Cur.)',
        FROM_DATE: 'From Date',
        TO_DATE: 'To Date',
        CHG_QTY: 'Charging Qty',
        CHG_QTY_UOM: 'Charging Qty UOM',  
        REV_COMPANY_CODE: 'Revenue Company Code',     
        REV_AC_CODE: 'Revenue A/C Code',
        COST_CENTER_CODE: 'Cost Center Code',
        PROD_SER_CODE: 'Product Service Code',
        SALES_CHANNEL_CODE: 'Sales Channel Code',
        COUNTRY_CODE: 'Country Code',
        PROJECT_CODE: 'Project Code',
        SPARE_CODE: 'Spare Code',
        INTER_COMPANY_CODE: 'Inter-company Code',
        TAX_PERCENTAGE: 'Tax%',
        TAX_AMT_INV_CURR: 'Tax Amount (Invoice Cur.)',
        TAX_AMT_CONT_CURR: 'Tax Amount (Contract Cur.)',
        TAX_AMT_LOC_CURR: 'Tax Amount (Local Cur.)',
        TX_TYPE: 'Transaction Type',
        ARTICLE_STATEMENT: 'Article Statement',
        TAX_CODE: 'Tax Code',
        TAX_PAYMENT_TERM: 'Tax Payment Term',
        TAX_PAYMENT_REF_TERM: 'Tax Payment Ref Date',
        TAX_DATE: 'Tax Date',

    },

    Modal: {
        DOC_TO_PRINT: 'DOCUMENTS TO PRINT',
        PRINT_WITH_SUPP: 'With Supporting Document',
        PRINTER: 'PRINTER',
        PRINTER_INV: 'IN/CN',
        PRINTER_SUPP: 'Supp Doc',
        CUST_SETTING: 'CUSTOM SETTING',
        AMT_DISPLAY: 'Amount Display',
        EXPORT: 'Export',
        ORIG_COPY: 'Original Copies',
        SUPP_COPY: 'Supporting Original Copies',
        WATERMARK: 'Print with Watermark'
    }
}