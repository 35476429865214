import { DocumentDetailItemEntity } from "domain/entity/Document/DocumentDetailItemEntity";
import { DocumentHeaderEntity, EMPTY_DOCUMENT_HEADER_ENTITY } from "domain/entity/Document/DocumentHeaderEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";


export interface DocumentEnquiryDetailChangeState extends BaseViewChangeSate {
    editingHeader: DocumentHeaderEntity,
}
     
export interface DocumentEnquiryDetailModel {
    isLoading:boolean,
    docHdr: DocumentHeaderEntity,
    docDtlItems: DocumentDetailItemEntity[],
    detailState: DocumentEnquiryDetailChangeState,
}

export const EMPTY_DOCUMENT_ENQUIRY_DETAIL_MODEL: DocumentEnquiryDetailModel = {
    isLoading: false,
    docHdr: EMPTY_DOCUMENT_HEADER_ENTITY,
    docDtlItems: [],
    detailState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: '',
        isSelected: false,

        allFormState: {},
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingHeader: EMPTY_DOCUMENT_HEADER_ENTITY
    }
}