import { DocumentEnquiryRepoImpl } from "domain/repository/Document/DocumentEnquiryRepoImpl";
import { useDocumentEnquiryDetailTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryDetailProvider";
import { DocumentEnquiryDetailVM } from "presentation/viewModel/DocumentEnquiry/DocumentEnquiryDetailVM";
import { useMemo } from "react";

export const useDocumentEnquiryDetailVM = () => {
    const [, setDocumentEnquiryDetailState] = useDocumentEnquiryDetailTracked();
    const docEnqDtlVM = useMemo(() =>
        DocumentEnquiryDetailVM({
            dispatch: [setDocumentEnquiryDetailState],
            docEnquiryRepo: DocumentEnquiryRepoImpl(),
        }), [setDocumentEnquiryDetailState])

    return docEnqDtlVM
}