import { EMPTY_DOCUMENT_ENQUIRY_MODEL } from 'presentation/model/DocumentEnquiry/DocumentEnquiryModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: DocumentEnquiryProvider,
    useTracked: useDocumentEnquiryTracked
} = createContainer(() => useState(EMPTY_DOCUMENT_ENQUIRY_MODEL), {concurrentMode: true});
export { DocumentEnquiryProvider, useDocumentEnquiryTracked };

