import { EMPTY_DOCUMENT_ENQUIRY_DETAIL_MODEL } from 'presentation/model/DocumentEnquiry/DocumentEnquiryDetailModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: DocumentEnquiryDetailProvider,
    useTracked: useDocumentEnquiryDetailTracked
} = createContainer(() => useState(EMPTY_DOCUMENT_ENQUIRY_DETAIL_MODEL), {concurrentMode: true});
export { DocumentEnquiryDetailProvider, useDocumentEnquiryDetailTracked };

