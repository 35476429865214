import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { DocumentHeaderEntity } from "domain/entity/Document/DocumentHeaderEntity";
import { DocumentEnquiryCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { DocumentEnquiryPrintCriteria } from "presentation/constant/DocumentEnquiry/DocumentEnquiryPrintCriteria";
import { DEFAULT_ENABLED_DOCUMENT_ENQUIRY_CRITERIA, EnabledDocumentEnquiryCriteria } from "presentation/constant/DocumentEnquiry/EnabledDocumentEnquiryCriteria";
import { RefObject } from "react";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";
import { DropdownProps } from "../DropdownProps";

export interface DocumentEnquiryDropdownOptions {
    chgOnCompDropdownOptions: DropdownProps[],
    billToCompDropdownOptions: DropdownProps[],
    chgTypeDropdownOptions: DropdownProps[],
    stateDropdownOptions: DropdownProps[],
    handlingTmlDropdownOptions: DropdownProps[],
    billingTypeDropdownOptions: DropdownProps[],
    customerCodeDropdownOptions: DropdownProps[],    
    fileLocationDropdownOptions: DropdownProps[],
    printQueueDropdownOptions: DropdownProps[],
}

export interface GroupCheckboxs {
    billingCycleCheckBoxOptions: GroupCheckboxList[],
}



export interface DocumentEnquiryModel {
    isLoading:boolean,
    isShowRightCriteriaPanel: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    checkboxRef: { [key: string]: HTMLElement | null },
    enabledSearchCriteria: EnabledDocumentEnquiryCriteria,
    checkboxValue: EnabledDocumentEnquiryCriteria,
    searchCriteria: DocumentEnquiryCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: DocumentEnquiryDropdownOptions,
    documentActive: boolean,
    isShowSaveNewConfirmModal: boolean,
    isShowDeleteConfirmModal: boolean,
    userEmail: string,
    searchPreferences: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria>[],
    currentSearchPreference: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria> | null,
    openedContainerNo?: string,
    isShowContainerDetail: boolean;
    docHdrs: DocumentHeaderEntity[],
    isShowLegendButton: boolean,
    selectedRows: DocumentHeaderEntity[],
    isShowPrintPad: boolean,
    docPrintCriteria: DocumentEnquiryPrintCriteria,
    dynamicGroupCheckboxs: GroupCheckboxs,
    currentHdr: DocumentHeaderEntity | null,
}

export const EMPTY_DOCUMENT_ENQUIRY_MODEL: DocumentEnquiryModel = {
    isLoading: true,
    isShowRightCriteriaPanel: true,
    isFilterSearchCriteria: true,
    isEditSearchCriteria: true,
    checkboxRef: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_DOCUMENT_ENQUIRY_CRITERIA },
    checkboxValue: { ...DEFAULT_ENABLED_DOCUMENT_ENQUIRY_CRITERIA },
    searchCriteria: { ...EMPTY_DOCUMENT_ENQUIRY_CRITERIA },
    searchCounter: 0,
    dynamicOptions: {
        chgOnCompDropdownOptions: [],
        billToCompDropdownOptions: [],
        chgTypeDropdownOptions: [],
        stateDropdownOptions: [],
        handlingTmlDropdownOptions: [],
        billingTypeDropdownOptions: [],
        customerCodeDropdownOptions: [],
        fileLocationDropdownOptions: [],
        printQueueDropdownOptions: []
    },
    documentActive: false,
    isShowSaveNewConfirmModal: false,
    isShowDeleteConfirmModal: false,
    userEmail: "",
    searchPreferences: [],
    currentSearchPreference: null,
    isShowContainerDetail: false,
    docHdrs: [],
    isShowLegendButton: false,
    selectedRows: [],
    isShowPrintPad: false,
    docPrintCriteria: {
        printWithSupp: false,
        invPrinter: "",
        invFileLoc: "",
        enableCustomSetting: false
    },
    dynamicGroupCheckboxs: {
        billingCycleCheckBoxOptions: []
    },
    currentHdr: null
}