import { INITIAL_DOCUMENT_ENQUIRY_DETAIL_COL_DEF, transferRowData } from "presentation/constant/DocumentEnquiry/DocumentEnquiryDetailColumnDefinition";
import { useDocumentEnquiryDetailTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryDetailProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";

const DocumentEnquiryDetailTablePanel:React.FC = () => {    
    const [ docEnqDtlState ] = useDocumentEnquiryDetailTracked();
    let gridRef: any = useRef();
    
    const memoDocEnqDtlTable = useMemo(() => {
        return <HPHTable
            id='document-enquiry-detail-table'
            columns={INITIAL_DOCUMENT_ENQUIRY_DETAIL_COL_DEF.slice()}
            data={transferRowData(docEnqDtlState.docDtlItems??[]) }
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)"
            ref={gridRef} />;
    }, [docEnqDtlState.docDtlItems])

    return <>{ <TableWrapper>{memoDocEnqDtlTable}</TableWrapper>}</>;
}

export default memo(DocumentEnquiryDetailTablePanel);